// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/About.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/Home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mares-js": () => import("./../../../src/pages/mares.js" /* webpackChunkName: "component---src-pages-mares-js" */),
  "component---src-pages-references-foals-js": () => import("./../../../src/pages/references-foals.js" /* webpackChunkName: "component---src-pages-references-foals-js" */),
  "component---src-pages-references-js": () => import("./../../../src/pages/references.js" /* webpackChunkName: "component---src-pages-references-js" */),
  "component---src-pages-sport-horses-js": () => import("./../../../src/pages/sport-horses.js" /* webpackChunkName: "component---src-pages-sport-horses-js" */),
  "component---src-pages-youngsters-js": () => import("./../../../src/pages/youngsters.js" /* webpackChunkName: "component---src-pages-youngsters-js" */),
  "component---src-templates-horse-js": () => import("./../../../src/templates/horse.js" /* webpackChunkName: "component---src-templates-horse-js" */)
}

